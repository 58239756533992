<template>
  <div class="home">
    <div class="header" @click="changePosition">
      <img
        :src="require(`@/assets/image/positioning.svg`)"
      />
      {{houseData.name}} <van-icon name="arrow" />
    </div>
    <div class="header-card">
      <div class="card-top">
        <div class="card-top-top" @click="changeRoles">
          <img :src="require(`@/assets/image/head-sculpture.png`)" alt="">
          王建伟
          <div class="card-mark"> 
            {{ rolesName }}
          </div>
          <!-- <div class="card-top-right">
            <img :src="require(`@/assets/image/edit.png`)" alt="">
            <span>完善信息</span>
          </div> -->
        </div>
        <!-- <div class="card-top-center">
          <div class="center-text">
            资料完善度
            <div>
              70%
            </div>
          </div>
          <van-progress color="#fff" :percentage="70" :show-pivot="false"/>
        </div> -->
        <div class="card-top-bottom">
          <div v-for="(item,index) in numData" :key="index" class="card-top-bottom-item">
            <div class="num">
              {{item.value}}
            </div>
            <div class="name">
              {{item.name}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="img-list">
      基础台账
      <div class="img-list-content">
        <div class="img-list-item" v-for="(item,index) in basicLedgerData" :key="index" @click="goBasicLedgerData(item.name)">
          <img :src="require(`@/assets/image/${item.image}`)" alt=""> <br/>
          {{item.name}}
        </div>
      </div>
      <div class="img-list-content">
        <div class="img-list-item" @click="goBasicLedgerData('治安责任书')">
          <img :src="require(`@/assets/image/public-security.png`)" alt=""> <br/>
          治安责任书
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="img-list">
      日常巡检
      <div class="img-list-content">
        <div class="img-list-item" v-for="(item,index) in inspectionData" :key="index" @click="goRouter(item.name)">
          <img :src="require(`@/assets/image/${item.image}`)" alt=""> <br/>
          {{item.name}}
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="img-list">
      其他
      <div class="img-list-content">
        <div class="img-list-item-bg" v-for="(item,index) in otherData" :key="index" @click="goOthers(item.name)">
          <img :src="require(`@/assets/image/${item.image}`)" alt=""> <br/>
          {{item.name}}
        </div>
      </div>
    </div>
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
    <van-action-sheet v-model="hoseShow" :actions="houseActions" @select="onSelectHourse" />
  </div>
</template>

<script>
import requests from "@/api/axios";

export default {
  name: 'HomeView',
  data(){
    return {
      loading: false,
      houseActions: [],
      houseData: "", 
      show: false,
      hoseShow: false,
      rolesName:"房东",
      actions: [{ name: '民警' }, { name: '房东' }, { name: '二房东' }, { name: "中介"}],
      numData:[
        {name:"房屋数",value:3},
        {name:"房间数",value:222},
        {name:"已出租",value:3},
        {name:"未出租",value:3},
      ],
      basicLedgerData: [
        {name:"房屋信息",image:"home.png"},
        {name:"消防设施",image:"fire-firghting.png"},
        {name:"租赁合同",image:"lease.png"},
        {name:"租客管理",image:"tenant.png"},
        {name:"二房东管理",image:"tenant.png"},
      ],
      inspectionData: [
        {name:"防火巡查",image:"fireproof.svg"},
        {name:"隐患整改",image:"hidden-danger.svg"},
      ],
      otherData: [
        {name:"法律法规",image:"law.svg"},
        {name:"通知公告",image:"notice.svg"},
        {name:"学习资料",image:"learn.svg"},
      ]
    }
  },
  created(){
    this.getHousingInformation();
    // this.getLongitudeLatitude();
  },
  methods: {
    // getLongitudeLatitude() {
    //   //如果该对象存在，那么地理位置服务可用。
    //   if ('geolocation' in navigator) {
    //     /* 地理位置服务可用 */
    //     var options = {
    //       enableHighAccuracy: true, //布尔值，表示系统是否使用最高精度来表示结果，注意，这会导致较慢的响应时间或者增加电量消耗（比如对于支持gps的移动设备来说）。如果值为false ，设备会通过更快响应以及/或者使用更少的电量等方法来尽可能的节约资源。默认值fasle
    //       timeout: 5000, //它表明的是设备必须在多长时间（单位毫秒）内返回一个位置。默认直到获取到位置才会返回值。
    //       maximumAge: 0 //表明可以返回多长时间（即最长年龄，单位毫秒）内的可获取的缓存位置。如果设置为 0, 说明设备不能使用一个缓存位置，而且必须去获取一个真实的当前位置。默认0
    //     }
    //     function success(position) {
    //       //position.coords (只读) 返回一个定义了当前位置的Coordinates对象。
    //       //position.timestamp (只读) 返回一个时间戳DOMTimeStamp， 这个时间戳表示获取到的位置的时间。
    //       var lat = position.coords.latitude //当前位置的纬度
    //       var lng = position.coords.longitude //当前位置精度
    //       console.log(lat,lng)
    //     }
    //     function error(err) {
    //       var errorType = ['您拒绝共享位置信息', '获取不到位置信息', '获取位置信息超时']
    //       console.log(errorType[err.code - 1])
    //     }
    //     navigator.geolocation.getCurrentPosition(success, error, options)
    //   } else {
    //     /* 地理位置服务不可用 */
    //     console.log('无法获取您的位置，请检查定位是否开启或刷新重试')
    //   }
    // }
    getHousingInformation(){
      this.loading = true;
      requests({url:'/app/room/list',method:'get'}).then((res)=>{
        this.houseActions = res.data.map((i)=>{
          return {
            name: i.villageName,
            id: i. id,
            buildingName: i.buildingName,
            unitName: i.unitName,
            roomName: i.roomName
          }
        });
        if(this.$route.query.index){
          this.houseData = this.houseActions[this.$route.query.index];
        }else{
          this.houseData = this.houseActions[0];
        }
        localStorage.setItem('houseData',JSON.stringify(this.houseData))
      }).catch(()=>{
        this.houseActions = [];
      }).finally(()=>{
        this.loading = false;
      });
    },
    changePosition(){
      if(this.houseActions.length > 7){
        this.$router.push('/HourseChose');
      }else{
        this.hoseShow = true;
      }
    },
    onSelectHourse(value){
      this.houseData = value;
      this.hoseShow = false;
    },
    onSelect(value){
      this.rolesName = value.name;
      this.show = false;
    },
    changeRoles(){
      this.show = true
    },
    goTenant(){
      this.$router.push("/Tenant");
    },
    goBasicLedgerData(value){
      switch (value){
        case '房屋信息':
          this.$router.push("/HousingInformation");
          break;
        case '租赁合同':
          this.$router.push("/LeaseContract");
          break;
        case "治安责任书":
          this.$router.push("/LettersOfResponsibility");
          break;
        case "消防设施":
          this.$router.push("/FireFacilities");
          break;
        case "租客管理":
          this.$router.push({path:"/TenantManagement",query:{
            name:"租客管理"
          }});
          break;
        case "二房东管理":
          this.$router.push({path:"/TenantManagement",query:{
            name:"二房东管理"
          }});
          break;
      }
    },
    goOthers(value){
      switch (value){
        case '租客申请':
          this.$router.push("/TenantApplication");
          break;
        case '法律法规':
          this.$router.push("/Law");
          break;
        case "通知公告":
          this.$router.push("/NoticeAnnouncement");
          break;
        case "学习资料":
          this.$router.push("/LearningMaterials");
          break;
      }
    },
    goRouter(value){
      if(value === '防火巡查'){
        this.$router.push({name:"FireInspection"});
      }else{
        this.$router.push({name:"Rectification"});
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.header{
  padding: 1rem 0 1rem 1rem;
  display: flex;
  img{
    height: 1rem;
    width: 1rem;
    margin-right: 0.2rem;
  }
}
.header-card{
  background: url("@/assets/image/background.png")
    100% no-repeat;
  background-size: 100% 100%;
  height: 12rem;
  color: #fff;
}
.card-top-top{
  margin-left: 1.5rem;
  padding-top: 1rem;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  img{
    margin-right: 0.5rem;
    height: 2.5rem;
    width: 2.5rem;
  }
  .card-mark{
    margin-left: 0.5rem;
    background-color: #fff;
    padding: 0.1rem 0.3rem;
    border-radius: 0.2rem;
    color: #00b17e;
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card-top-right{
    margin-left: auto;
    margin-right: 1.5rem;
    display: flex;
    align-items: center;
    img{
      height: 0.9rem;
      width: 0.9rem;
    }
    span{
      display: inline-block;
      font-size: 0.8rem;
    }
  }
}
.card-top-center{
  margin: 5% 5%;
}
.center-text{
  display: flex;
  color: #fff;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  div{
    margin-left: auto;
  }
}
.card-top-bottom{
  display: flex;
  margin: 2rem 1.5rem;
  .card-top-bottom-item{
    flex: 1;
    border-right: 0.5pt solid #6ce0bf;
    .num{
      text-align: center;
      font-size: 1.5rem;
      margin-bottom: 0.3rem;
      font-family: DIN;
    }
    .name{
      text-align: center;
      font-size: 0.8rem;
    }
    &:last-child{
      border-right: none;
    }
  }
}
.van-progress{
  background:#38c9a0;
}
.img-list{
  margin: 5% 1.5rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0,0,0,0.85);
  .img-list-content{
    margin-top: 1rem;
    display: flex;
    .img-list-item{
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.7rem;
      img{
        height: 2.5rem;
        width: 2.5rem;
      }
    }
    .img-list-item-bg{
      width: 25%;
      height: 4rem;
      font-size: 0.7rem;
      margin-right: 0.5rem;
      padding: 5% 0 0 5%;
      img{
        height: 2rem;
        width: 2rem;
        margin-bottom: 5%;
      }
      &:nth-child(1){
        background-color: rgba(242, 244, 255, 1);
      } 
      &:nth-child(2){
        background-color: rgba(255, 243, 243, 1);
      }  
      &:nth-child(3){
        background-color: rgba(247, 242, 255, 1);
      }  
      &:nth-child(4){
        background-color: rgba(255, 248, 237, 1);
      }   
    }
  }
}
.line{
  width: 100%;
  height: 0.2rem;
  background-color: rgba(246, 249, 249, 1);
  margin-top: 1rem;
}
</style>