import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import "./assets/style/reset.scss"
import vueEsign from 'vue-esign'
// import vConsole from 'vconsole'
// let Console = new vConsole()

// Vue.use(Console)

Vue.use(vueEsign)
Vue.use(Vant);
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
