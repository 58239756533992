import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  // {
  //   path: '/',
  //   name: 'TenantMessage',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/TenantMessage.vue')
  // },
  {
    path: "/TenantMessage",
    name: "TenantMessage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TenantMessage.vue"),
  },
  {
    path: "/Home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/HousingInformation",
    name: "HousingInformation",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HousingInformation.vue"),
  },
  {
    path: "/HourseChose",
    name: "HourseChose",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HourseChose.vue"),
  },
  {
    path: "/AddHousingResources",
    name: "AddHousingResources",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AddHousingResources.vue"
      ),
  },
  {
    path: "/DetailHouseInformation",
    name: "DetailHouseInformation",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/DetailHouseInformation.vue"
      ),
  },
  {
    path: "/LeaseContract",
    name: "LeaseContract",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LeaseContract.vue"),
  },
  {
    path: "/LettersOfResponsibility",
    name: "LettersOfResponsibility",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/LettersOfResponsibility.vue"
      ),
  },
  {
    path: "/FireFacilities",
    name: "FireFacilities",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FireFacilities.vue"),
  },
  {
    path: "/TenantManagement",
    name: "TenantManagement",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TenantManagement.vue"),
  },
  {
    path: "/AddTenantManagement",
    name: "AddTenantManagement",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AddTenantManagement.vue"
      ),
  },
  {
    path: "/TenantDetail",
    name: "TenantDetail",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TenantDetail.vue"),
  },
  //租客扫描增加信息后的提示
  {
    path: "/TenantTip",
    name: "TenantTip",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TenantTip.vue"),
  },
  {
    path: "/AddFireFacilities",
    name: "AddFireFacilities",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AddFireFacilities.vue"),
  },
  {
    path: "/TenantApplication",
    name: "TenantApplication",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/landlordOthers/TenantApplication.vue"
      ),
  },
  {
    path: "/Law",
    name: "Law",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/landlordOthers/Law.vue"),
  },
  {
    path: "/LearningMaterials",
    name: "LearningMaterials",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/landlordOthers/LearningMaterials.vue"
      ),
  },
  {
    path: "/DetailOthers",
    name: "DetailOthers",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/landlordOthers/DetailOthers.vue"
      ),
  },
  {
    path: "/NoticeAnnouncement",
    name: "NoticeAnnouncement",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/landlordOthers/NoticeAnnouncement.vue"
      ),
  },
  {
    path: "/FireInspection",
    name: "FireInspection",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/DailyInspection/FireInspection.vue"
      ),
  },
  {
    path: "/Rectification",
    name: "Rectification",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/DailyInspection/Rectification.vue"
      ),
  },
  {
    path: "/FireInspectionDetail",
    name: "FireInspectionDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/DailyInspection/FireInspectionDetail.vue"
      ),
  },
  {
    path: "/AddFireInspection",
    name: "AddFireInspection",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/DailyInspection/AddFireInspection.vue"
      ),
  },
  {
    path: "/Tenant",
    name: "Tenant",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Tenant/index.vue"),
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
